// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  authRedirectUri: '/',
  authPostLogoutRedirectUri: '/',
  datexApplicationApiUrl: '/api/',
  integrationHubConfigApiUrl: 'https://datexintegrationhub-configurationapi-prod.azurewebsites.net/configapi/',
  integrationHubConfigurationApiScopes: ['api://6fd69ce1-e9ca-4107-b5aa-3c9c7b08b137/.default'],
  legacyConnectorsConfigApiUrl: 'https://datex-file-watcher-configuration-api-prod.azurewebsites.net/',
  legacyConnectorsConfigApiScopes: ['api://542d189e-dd86-4316-a36a-77a779d17741/.default'],
  integrationHubApiUrl: 'https://api.datexintegrationhub.com/api/',
  integrationHubApiScopes: ['api://e577ba0d-6a9d-4e94-8a14-97f6b649dcd4/.default'],
  integrationHubLoggingApiUrl: 'https://datexintegrationhub-logging-api-prod.azurewebsites.net/hubapi/',
  integrationHubLoggingApiScopes: ['api://f86dfd87-958e-40ef-a5ec-0c90a79f3e96/.default'],
  datexAlertApiUrl: 'https://datex-alert-services-prod.azurewebsites.net/odata/',
  datexAlertApiScopes: ['api://95fda1ae-e7b7-4b30-9f73-a4a6ac3835c8/.default'],
  datexAlertRecommndedSolutionApiUrl: 'https://ca-alerts-suggestion-api.gentleflower-459ce483.eastus.azurecontainerapps.io/',
  datexAlertRecommndedSolutionApiScopes: ['api://6a655f5f-071d-45af-9264-e64ed357235a/.default'],
  kustoQueryApiUrl: 'https://ca-integrationhub-kustoquery-api.salmonflower-9c1fbcb8.eastus.azurecontainerapps.io/',
  kustoQueryApiScopes: ['api://b6ca991d-958f-4474-87bd-ee0e78f613e5/.default'],
  footPrintAdaptorApiUrl: 'https://datexintegrationhub-footprint-prod.azurewebsites.net/api/',
  footPrintAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  shopifyAdaptorApiUrl: 'https://datexintegrationhub-shopify-prod.azurewebsites.net/api/',
  shopifyAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  wooCommerceAdaptorApiUrl: 'https://datexintegrationhub-woocommerce-prod.azurewebsites.net/api/',
  wooCommerceAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  bigCommerceAdaptorApiUrl: 'https://datexintegrationhub-bigcommerce-prod.azurewebsites.net/api/',
  bigCommerceAdaptorApiScopes: ['api://54e03cb6-6605-40d5-bcae-fe5eec98681e/.default'],
  mesciusLicenseKey: '*.wavelength.host,232864259645381#B14TeF5RIp4MypGOpdlYCFnUtZlMZhkRwJUd5E4Z5wUY4hTR8FDSZdmeqhTSXNXM5YGR6FWM6YzVJdTS0JUaslEbWhFb5czdt5EaFdHckRVQ5RFRH3yM73yLXhGeMpWeFZ4ZShmdIFGVrYWMDNWS6hHdVl7S7U5Z5skdqF4UxpkRxE4NChGa5o5L5U4bBpkMQlzZrR4Q9ETdZBlb8l4d0hncSF4YQlWMtJkdhlWRrYTNplWcylleRZXRwlWTWxWcQxWelh6VMhEcatEMHNDdIFFb65WUXFUOMJkYLJWQZJDOCR7QaZTVQFEUmljSnBFWiojITJCLiQTNwQUN4kjNiojIIJCLzgTO9QjMyYzM0IicfJye35XX3JiVMtkTiojIDJCLiUjVgMlS4J7bwVmUlZXa4NWQiojIOJyebpjIkJHUiwiIxATNyITMggjMwEDNyAjMiojI4J7QiwiI4N7bo9Ca4dmblxWZ6F6duoiI0IyctRkIsICelRXYEJiOiEmTDJCLiEDOzUDN6kTNyQjN8IzMyIiOiQWSiwSfdtlOicGbmJCLlNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRp5U7UUW4Q6QhlUMKJVaI5Ee9lDVh5kS4UXRPh7T6I4LwVXSrZDa4ZVbLZWNnV6aUxGU8gzSrVXWIhzVC56c7AzbjhjWHNTNBVDTuVmYKpFOTR5crJldUBHSXJUZSZ7QEdzUI5zNKx',
  ediLogicAppTriggerUrl: 'https://as2.datexintegrationhub.com',
  wavelengthAccountConnectorUrl: 'https://wavelength-connector-edi-prod.eastus.cloudapp.azure.com/',
  authtok: 'gJxjmNebq3g0M9XIEcXHa2PiODl8roVO',
  copilotApiUrl: 'https://copilot.wavelength.host/api/codecopilot/analyze/',
  copilotApiScopes: ['api://b24fc21e-e3f5-48c1-9769-77ed844e7b66/.default'],
  icmApiUrl: 'https://icm.wavelength.host/odata/',
  icmApiScopes: ['api://ad5d6ff0-895d-4d96-aa09-62ddc99c68de/.default'],
  ediPublic: 'https://as2.footprint.host/AS2Ingress',
  ediPrivate: 'https://wavelength-edi-prod.eastus.cloudapp.azure.com/AS2Ingress',
  datexDefaultAlertMessageTemplate: 'https://datexintegrationhubprod.blob.core.windows.net/datex-alert-services/html-email-templates/IntegrationHubDefault.html'
};
